import store from "@/store/index";
import Vue from "vue";
const visitSubSystem = (url) => {
  let redirect = `https://ip.staging.hetutec.com/user/login?client_id=1675809103999406081&response_type=code&redirect_url=https://staging.api.ip.hetutec.com/v2/portal/users/grant?location=${url}`
  redirect = encodeURI(redirect);
  window.open(redirect, "_blank");
};

const systems = {
  middle_systems: [
    {
      name: "星存证",
      abstract: `中国（湖南）自由贸易试验区数字知识产权保护平台通过技术手段为任意数据生成不可篡改的证据，证明数据在某个时间点存在，同时无需对外暴露数据。
            具有保护隐私、低成本和快捷的优势，可以方便且安全地嵌入数据生产的流程中，
            提供更细粒度的产权证明和制造过程存证。
            数字知识产权保护在知识产权的前置确权阶段发挥作用，
            为传统知识产权的确权和维权提供更有力的保障。`,
      url: "https://ip.hndip.cn",
      intro_url: "https://hndip.cn/proof-intro",
      needToken: false,
      img_url: null,
      celestialService: true,
      visit: (url) => {
        window.open(`${url}`, "_blank");
      },
      asset_url: require("@/assets/xingcunzheng.svg"),
    },
    {
      name: "星检索",
      abstract: `该系统涵盖分析子系统、法律检索模块、重点产业专利导航专题库，整合国内外专利、商标、版权等知识产权数据资源，实现数据检索、分析等多种功能，是国内领先的知识产权大数据应用系统，提供专利检索、商标检索、标准检索、版权检索、集成电路检索等功能，旨在为创新主体提供最优质、高效的知识产权信息服务。`,
      url: "http://dsj21.publicdi.com:9580/txnlogin.do",
      intro_url: null,
      needToken: true,
      img_url: null,
      celestialService: true,
      visit: visitSubSystem,
      asset_url: require("@/assets/xingjiansuo.svg"),
    },
  ],
  public_services: [
    {
      name: "星俊才",
      abstract: `通过专利应用价值、创新水平和权利有效性等全方位分析，为发明人参与科技创新技术的水平评估、应用前景等提供有效评议支撑。`,
      url: "http://dsj19.publicdi.com",
      intro_url: null,
      needToken: false,
      img_url: null,
      celestialService: true,
      visit: (url) => {
        window.open(`${url}`, "_blank");
      },
      asset_url: require("@/assets/system_elite.png"),
    },
    {
      name: "星助理",
      abstract: `采取知识库和智能化语义技术，为用户提供最佳的专利申请文件撰写辅助工具。`,
      url: "http://dsj17.publicdi.com/sso_changsha.aspx",
      intro_url: null,
      needToken: true,
      img_url: null,
      celestialService: true,
      visit: visitSubSystem,
      asset_url: require("@/assets/system_assistant.png"),
    },
    {
      name: "星代理",
      abstract: `专业的专利代理机构检索、专利代理机构排行查询工具。`,
      url: "http://dsj16.publicdi.com/otherservice/index_cs.html",
      intro_url: null,
      img_url: null,
      needToken: false,
      celestialService: true,
      visit: (url) => {
        window.open(`${url}`, "_blank");
      },
      asset_url: require("@/assets/system_delegate.png"),
    },
    {
      name: "项目申报系统",
      abstract: `高效的知识产权行政事务和项目申报在线审批的政务管理系统，通过信息化手段改善、规范政府部门政务审批过程，有效提高政务效率。`,
      url: "http://114.247.84.45:9001/huNanyypt/member/item/add.jhtml",
      intro_url: null,
      img_url: null,
      needToken: true,
      visit: visitSubSystem,
      asset_url: require("@/assets/project_submit_system.png"),
    },
    {
      name: "智能装备系统",
      abstract: `高端装备制造产业提供专利技术导航系统，提供高端制造业领域的价值分布。`,
      url: "http://e.cnipr.com/inma/topic/overview.jhtml",
      intro_url: null,
      img_url: null,
      needToken: false,
      visit: (url) => {
        window.open(`${url}`, "_blank");
      },
      asset_url: require("@/assets/smart_equipment_system.png"),
    },
    {
      name: "新一代信息技术系统",
      abstract: `电子信息产业提供专利技术导航系统，提供电子信息技术产业的价值分布。`,
      url: "http://e.cnipr.com/infn/topic/overview.jhtml",
      intro_url: null,
      needToken: false,
      visit: (url) => {
        window.open(`${url}`, "_blank");
      },
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
  ],
  right_services: [
    {
      name: "长沙分中心介绍",
      route: "serviceCenterIntro",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
    {
      name: "海外纠纷申报与受理",
      route: "serviceApply",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
    {
      name: "海外知识产权动态及要点信息",
      route: "serviceInfo",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
    {
      name: "维权援助",
      route: "serviceHelp",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
    {
      name: "其他信息",
      route: "serviceOthers",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
  ],
  business_services: [
    {
      name: "专利业务",
      route: "businessPatent",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
    {
      name: "商标业务",
      route: "businessTrademark",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
    {
      name: "其他",
      route: "businessOthers",
      img_url: null,
      asset_url: require("@/assets/new_generation_information_system.png"),
    },
  ],
};
Vue.prototype.$url_config = {
  VUE_APP_URL_PROOF: process.env.VUE_APP_URL_PROOF,
  VUE_APP_URL_SEARCH: process.env.VUE_APP_URL_SEARCH,
  VUE_APP_URL_ELITE: process.env.VUE_APP_URL_ELITE,
  VUE_APP_URL_ASSISTANT: process.env.VUE_APP_URL_ASSISTANT,
  VUE_APP_URL_DELEGATE: process.env.VUE_APP_URL_DELEGATE,
  VUE_APP_URL_PROOF_INRO: process.env.VUE_APP_URL_PROOF_INRO,
};

export const MenuItems = systems.middle_systems
  .filter((itm) => itm.celestialService)
  .map((itm) => {
    return {
      caption: itm.name,
      command: {
        callback: () => {
          if (itm.needToken) visitSubSystem(itm.url);
          else window.open(`${itm.url}`, "_blank");
        },
      },
      asset_url: itm.asset_url,
      img_url: itm.img_url,
    };
  })
  .concat(
    systems.public_services
      .filter((itm) => itm.celestialService)
      .map((itm) => {
        return {
          caption: itm.name,
          command: {
            callback: () => {
              if (itm.needToken) visitSubSystem(itm.url);
              else window.open(`${itm.url}`, "_blank");
            },
          },
          asset_url: itm.asset_url,
          img_url: itm.img_url,
        };
      })
  );
export const RightMenuItems = systems.right_services.map((item) => {
  return {
    caption: item.name,
    command: item.route,
    asset_url: item.asset_url,
    img_url: item.img_url,
  };
});
export const BusinessMenuItems = systems.business_services.map((item) => {
  return {
    caption: item.name,
    command: item.route,
    asset_url: item.asset_url,
    img_url: item.img_url,
  };
});
export default systems;
